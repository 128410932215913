
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.surveys {
  &__pagination {
    margin-top: $base-gutter;
    justify-content: center;
  }
  &__actions {
    display: flex;
    flex-direction: row;
  }
  .esmp-table__thead-tr {
    .esmp-table__thead-th:first-child {
      width: 42px;
    }
    .esmp-table__thead-th:nth-child(3) {
      width: 48px;
    }
    .esmp-table__thead-th:last-child {
      width: 70px;
    }
  }
}
